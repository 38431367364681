<template>
  <div>
    <div class="col float-right">
      <q-btn-group v-show="editable&&(completeFlag||!deleteDisabled)" outline>
        <c-btn
          v-show="param.returnRemark"
          label="반려사유"
          icon="keyboard_return"
          @btnClicked="returnDialogOpen" />
        <c-btn 
          v-show="!deleteDisabled" 
          label="위험성평가 삭제" 
          icon="remove" 
          @btnClicked="removePlan" />
        <c-btn 
          v-show="completeFlag"
          :isSubmit="isRequest"
          :url="requestUrl"
          :param="assessPlan"
          mappingType="PUT"
          label="평가완료요청" 
          icon="check"
          @beforeAction="requestAction"
          @btnCallback="requestActionCallback" />
      </q-btn-group>
    </div>
    <c-tab
      ref="4mActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :height="height"
          :process.sync="tab.process"
          :param.sync="param"
          :planUpdateBtnData="param.planUpdateBtnData"
          :isVendorRequest="isVendorRequest"
          @assessComplete="assessComplete"
          @research="research"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
    <q-dialog v-model="returnDialog.show" persistent>
      <q-card style="min-width: 450px">
        <q-form ref="editForm2">
          <c-card title="반려 사유" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  label="닫기" 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  :readonly="true"
                  :rows="5"
                  label=""
                  name="returnRemark"
                  v-model="param.returnRemark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fm-action',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        returnRemark: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: '계획',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      returnDialog: {
        show: false,
      },
      isVendorRequest: false,
      editable: true,
      isRequest: false,
      listUrl: '',
      requestUrl: '',
      deletePlanUrl: '',
    };
  },
  computed: {
    completeFlag() {
      return this.tabItems 
        && this.tabItems.length > 0
        && this.$_.findIndex(this.tabItems, (item) => {
              return item.process.ramProcessAssessStepCd !== 'RPA0000005'
            }) === -1 
        && this.param.ramStepCd === 'R4RS00010'
        && !this.isVendorRequest
    },
    assessPlan() {
      return {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
        ramStepCd: this.param.ramStepCd,
        chgUserId: this.$store.getters.user.userId,
      }
    },
    deleteDisabled() {
      return this.param.ramStepCd === 'R4RS00015'
    },
  },
  watch: {
    'param.searchScenario.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.fm.targetProcess.url;
      this.requestUrl = transactionConfig.ram.assessPlan.request.url
      this.deletePlanUrl = transactionConfig.ram.assessPlan.delete.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tabItems = [];
        this.$_.forEach(_result.data, item => {
          this.tabItems.push({
            key: uid(),
            name: item.processCd, icon: 'work_outline', label: item.processName, process: item, component: () => import(`${'./4mScenario.vue'}`)
          })
          this.isVendorRequest = item.vendorRequestFlag === 'Y'
        })
        this.tab = _result.data[0].processCd
        this.$refs['4mActionTab'].setValue(this.tab)
      },);
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      if (stepCd === 'R4RS00015') {
        this.$emit('emitStep', {
          name: 'stepBystep',
          param: stepCd
        })
      }
    },
    research(place) {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: place,
        }
      })
    },
    requestAction() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '평가완료 요청하시겠습니까?\n\r(※ 승인자에게 요청이 갑니다.)',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.assessPlan.chgUserId = this.$store.getters.user.userId
          this.assessPlan.ramStepCd = 'R4RS00015'

          this.isRequest = !this.isRequest
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    requestActionCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getList();
      // stepper 이동
      // this.$emit('emitStep', {
      //   name: 'stepBystep',
      //   param: this.assessPlan.ramStepCd
      // })
    },
    returnDialogOpen() {
      this.returnDialog.show = true;
    },
    closeDialog() {
      this.returnDialog.show = false;
      Object.assign(this.$data.returnDialog, this.$options.data().returnDialog);
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deletePlanUrl, this.param.ramRiskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.closePopup()
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    }
  }
};
</script>
